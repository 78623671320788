<script>
import { mapState } from 'vuex';

import { BIconTrash,BBadge, BIconSearch, BIconBack, BSpinner,BIconPeopleFill } from 'bootstrap-vue';

import formatDate from '@/mixins/formatDate';
import trialHelpers from '@/mixins/trialHelpers';
import agreementTitle from '@/lib/agreement-title';
import { analytics } from '../../firebase';
import TrialBanner from '../../components/TrialBanner.vue';
import ClioImport from '../../clioImport.vue';
import {hasEditPermission, hasDeletePermission, hasAddPermission} from '@/mixins/roleLevelPermissions';
import SubscriptionModal from '../../components/SubscriptionModal.vue';

export default {
	name: 'AgreementsHome',
	components: {
		BIconTrash,
		TrialBanner,
		BBadge,
		ClioImport,
		BIconSearch,
		BIconBack,
		BSpinner,
		BIconPeopleFill,
		SubscriptionModal
	},
	data() {
		return {
			fields: [
				{
					class: 'checkbox',
					key: 'checkBox',
					label: '',
				},
				{
					class: 'nameColumn',
					key: 'name',
					sortable: true,
				},
				{
					class: 'descriptionColumn',
					key: 'description',
					sortable: false,
				},
				{
					class: 'dateColumn',
					key: 'updatedAt',
					label: 'Last edited',
					formatter: this.dateFormatter,
					sortable: true
				},
				{
					class: 'deleteColumn',
					key: 'delete',
					label: '',
				},
				{
					class: 'duplicateColumn',
					key: 'duplicate',
					label: '',
				},
			],
			showDeleteConfirmation: false,
			showDuplicateConfirmation: false,
			deleteId: null,
			duplicateId: null,
			showAgreementLimit: false,
			agreementLimitModalOptions: {
				heading:'',
				message:'',
				button:'',
			},
			agreementDeleteModalOptions: {
				heading:'',
				message:'',
			},
			agreementDuplicateModalOptions: {
				heading:'',
				message:'',
			},
			checkedAgreements: [],
			selectAll: false,
			deleteMultiple: false,
			deleteLoader:false,
			searchTerm:'',
			showClioModal:false,
			saveDetails: false,
			clioLoading:false,
			showAddClient:false,
			loading: false,
			duplicateAgreementTitle:'',
			duplicateAgreementDetails: [],
			iscloned: false,
			clonedId:'',
			cloning:false,
			cloningStatus: {},
			showSubscriptionModal:false
		};
	},
	methods: {
		agreementTitle,
		// addNewClientMethod(){
		// 	if(this.validateSubscriptionLimitReached.limitReached){
		// 				this.showAgreementLimit = true;
		// 				this.agreementLimitModalOptions={
		// 					heading:'Plan limit reached',
		// 					message : `Oops! It looks like you've already created ${this.subscriptions[0].role.substring(0,1)} new agreements this month. Need to create more files fast? Upgrade to Pro and create unlimited new files whenever you need.`,
		// 					button:"Upgrade Now"
		// 				}
		// 				return;
		// 	} else if(this.validateTrialAgreementsEnded()){
		// 		this.showAgreementLimit = true;
		// 		this.agreementLimitModalOptions={
		// 			heading:'Trial Limit',
		// 			message : "You're currently on a Divii Free Trial which only supports one client file. If you'd like to create more files, you can upgrade to a paid version of Divii.",
		// 			button: "Subscribe"
		// 		}
		// 		return;
		// 	}

		// },
		hideAddClient(){
			this.showAddClient = false;
		},
		clioShowModal(){
			this.showClioModal = true;
		},
		hideClioModal(){
			this.showClioModal = false;
			this.showAddClient = false;
			// this.loader()
		},
		loader(value){
			console.log("isLoading: ",value)
			this.clioLoading = value;
		},
		async deleteAgreement() {
			if (!this.deleteId) return;
			this.$store.dispatch('deleteAgreement', this.deleteId).then(() => {
				analytics.logEvent('agreement_deleted');
				this.showDeleteConfirmation = false;
				this.checkedAgreements = this.checkedAgreements.filter(a=>a === this.deleteId)
			});
		},
		loadAgreementData(duplicateId) {
			this.$store.dispatch('fetchAgreement', {
				id: duplicateId,
				realtime: true
			});
		},
		async duplicateAgreement() {
			setTimeout(() => {
				this.$set(this.cloningStatus, this.duplicateAgreementDetails.id, true);
			}, 200);
			this.agreementData = this.duplicateAgreementDetails;
			this.$store.dispatch('createAgreement', {
				...this.agreementData,
				title: this.duplicateAgreementTitle,
			}).then(({ id, error }) => {
				this.clonedId = id;
				if (error) {
				this.error = 'Something unexpected went wrong.';
				} else if (id) {
				this.iscloned=true;
				analytics.logEvent('agreement_created');
				}
			}).finally(() => {
				this.$set(this.cloningStatus, this.duplicateAgreementDetails.id, false);
			});
		},
		showDeleteModel(id,option) {
		if(this.trialExpired){
			this.showSubscriptionModal = true
		}
		else{
			this.formatDeleteModalContent(option);
			this.deleteId = id;
			this.showDeleteConfirmation = true;
			if(option === "multiple"){
				this.deleteMultiple = true
			}
			else if(option === "single"){
				this.deleteMultiple = false
			}
		}
		},
		showDuplicateModel(agreement) {
			this.loadAgreementData(agreement.id);
			setTimeout(() => {
				this.duplicateAgreementDetails=this.$store.getters.agreementDetails;
			}, 1000);
			this.formatDuplicateModalContent();
			this.duplicateId = agreement.id;
			this.duplicateAgreementTitle = agreement.title;
			this.showDuplicateConfirmation = true;
		},
		hideModal() {
			this.deleteId = null;
			this.showDeleteConfirmation = false;
			this.deleteMultiple = false
		},
		hideDuplicateModal() {
			this.duplicateId = null;
			this.showDuplicateConfirmation = false;
		},
		getSubscriptions(){
			if (this.user && !this.subscriptions)
				this.$store.dispatch('getSubscriptions', this.user);
		},
		addNewClient(){
		if(this.trialExpired){
			this.showSubscriptionModal = true;
		}
		else{
			if(this.validateSubscriptionLimitReached.limitReached){
						this.showAgreementLimit = true;
						this.agreementLimitModalOptions={
							heading:'Plan limit reached',
							message : `Oops! It looks like you've already created ${this.subscriptions[0].role.substring(0,1)} new agreements this month. Need to create more files fast? Upgrade to Pro and create unlimited new files whenever you need.`,
							button:"Upgrade Now"
						}
						return;
			} else if(this.validateTrialAgreementsEnded()){
				this.showAgreementLimit = true;
				this.agreementLimitModalOptions={
					heading:'Trial Limit',
					message : "You're currently on a Divii Free Trial which only supports one client file. If you'd like to create more files, you can upgrade to a paid version of Divii.",
					button: "Subscribe"
				}
				return;
			} 

			if(localStorage.getItem('loginProvider') == 'CLIO' || localStorage.getItem('isClioFetch') == 'true'){
				this.showAddClient = true;
			} else {
				this.routeToCreateAgreement();
			}
		}
		},
		routeToCreateAgreement(){
			this.$router.push({ name: 'createAgreement' });
		},
		validateTrialAgreementsEnded(){
			const currentAgreementsLength = this.filteredAgreements.length;
			// const allAgreementsLength = this.agreements.length;
			if((this.trialActive && currentAgreementsLength > 0) || this.trialExpired){
				return true;
			} 
			return false;
		},
		getMaxAgreementsAllowed(){
			let limitForAgreements = null;
				if((this.subscriptions && this.subscriptions[0].role) && (this.subscriptions[0].role.includes('monthly'))){
					limitForAgreements = parseInt(this.subscriptions[0].role.substring(0,1));
				}
			return limitForAgreements;
		},
		formatDeleteModalContent(option){
			const maxLimit = this.getMaxAgreementsAllowed();
			if(option === "single"){
				this.agreementDeleteModalOptions= {
				heading:'Delete Agreement?',
				message:'Are you sure you want to permanently destroy this agreement?',
				}
			}
			if(option === "multiple"){
				this.agreementDeleteModalOptions= {
				heading:'Delete Agreements?',
				message:'Are you sure you want to permanently destroy selected agreements?',
				}
			}
			if(maxLimit){
				this.agreementDeleteModalOptions['message'] += `Keep in mind that on your current plan you can only create ${maxLimit} new agreements per billing cycle.`
			}
		},
		formatDuplicateModalContent(){
				this.agreementDuplicateModalOptions= {
				heading:'Duplicate Agreement?',
				message:'Are you sure you want to duplicate this agreement?',
			}
		},
		handleSelectAll(checked) {
			if (checked) {
				this.checkedAgreements = this.searchResults.filter(a=> a.owner).map(a => a.id)
			} else {
				this.checkedAgreements = []
			}
		},
		async deleteMultipleAgreements(){
			if (!this.checkedAgreements) return;
			this.deleteLoader = true
			this.$store.dispatch('deleteMultipleAgreements', this.checkedAgreements).then(() => {
				analytics.logEvent('agreements_deleted');
				this.showDeleteConfirmation = false;
				this.checkedAgreements = [];
				this.deleteLoader = false
			})
			.catch((error) => {
			console.error('An error occurred while deleting agreements:', error);
			this.deleteLoader = false;
			});
		},
		sortCompare(a, b, sortBy) {
			if (sortBy === 'name') {
				const nameA = a.title.toLowerCase()
				const nameB = b.title.toLowerCase()
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
				return 0
			} else if (sortBy === 'updatedAt') {
				const dateA = new Date(a.updatedAt)
				const dateB = new Date(b.updatedAt)
				return dateB - dateA
			} else {
				return a[sortBy] - b[sortBy]
			}
		},
		isDuplicationAllowed(agreement){
		if(this.trialExpired){
			this.showSubscriptionModal = true;
		}
		else{
			if((this.user?.isSharedUser === true || this.currentAgreementRoleLevelName ==='client-sharing-user') && this.trialActive){
				this.showAgreementLimit = true;
				this.agreementLimitModalOptions={
					heading:'Trial Limit',
					message : "You're currently on a Divii Free Trial which does not support duplication. If you'd like to duplicate agreements, you can upgrade to a paid version of Divii.",
					button: "Subscribe"
				}
				return;
			}
			else{
				this.showDuplicateModel(agreement);
			}
		}
	}
	},
	computed: {
		...mapState(['user','agreements', 'userProfile','subscriptions','currentAgreementRoleLevelName']),
		loadingAgreements() {
			return this.$store.getters.loading;
		},
		filteredAgreements() {
			return this.agreements.filter(({ archived }) => !archived);
		},
		agreementsCountLeft(){
			return this.validateSubscriptionLimitReached.agreementsLeft >= 0 ? this.validateSubscriptionLimitReached.agreementsLeft : null;
		},
		// canCreate() {
		// 	console.log('subscriptions',this.subscriptions[0].role)
		// 	return (this.trialActive && this.filteredAgreements.length > 0) ||
		// 		this.trialExpired
		// 		? false
		// 		: true;
		// },
		validateSubscriptionLimitReached(){
			const allowedAgreements = this.getMaxAgreementsAllowed();
			let limitResponse = {
				limitReached : false,
				agreementsLeft: null
			};
			if(allowedAgreements){
					// let agreementsInCurrentTimePeriod = this.filteredAgreements.filter(agreement=> this.dateLiesInsideDateRange(this.subscriptions[0].current_period_start.seconds,this.subscriptions[0].current_period_end.seconds,agreement.createdOn) == true)
					let agreementsInCurrentTimePeriod = this.agreements.filter(agreement=> this.dateLiesInsideDateRange(this.subscriptions[0].current_period_start.seconds,this.subscriptions[0].current_period_end.seconds,agreement.createdOn) == true)
					limitResponse['agreementsLeft'] = allowedAgreements - agreementsInCurrentTimePeriod.length;
					if(allowedAgreements <= agreementsInCurrentTimePeriod.length){
						limitResponse['limitReached']= true;
						return limitResponse
					}
			}
			return limitResponse;
		},
		searchResults(){
			if (this.searchTerm && this.searchTerm.length >= 3) {
				const searchWords = this.searchTerm.trim().toLowerCase().split(' ');
				return this.filteredAgreements.filter(result => {
					const title = result.title.toLowerCase();
					return searchWords.every((word) => title.includes(word));
				});
			} else {
				return this.filteredAgreements;
			}
		},
		isViewMode(){return !hasEditPermission('agreements')}, 
		isAddAllowed(){return hasAddPermission('agreements')},
		isDeleteAllowed(){return hasDeletePermission('agreements')}
	},
	mounted() {
		this.getSubscriptions();
		this.$root.$emit('agreements-list-loaded');
	},
	mixins: [formatDate, trialHelpers],
	watch: {
		selectAll(checked) {
			this.handleSelectAll(checked)
		},
	},
};
</script>

<style>
.nameColumn {
	width: 45%;
	background-position: left calc(9.75rem /2) center !important;
}

.home-wrapper {
	z-index: 2;
	min-height: 100vh;
	width: calc(100% + 5vw) ;
	margin-left: -2.5vw;
}

.circle-one {
	fill: #d4f0f0;
	opacity: 1;
	left: -40px;
	top: 100px;
	position: absolute;
}

.circle-two {
	fill: #e1efba;
	opacity: 0.6;
	left: 100px;
	top: 480px;
	position: absolute;
}

.circle-three {
	fill: #e1efba;
	opacity: 0.6;
	right: 80px;
	top: 150px;
	position: absolute;
}

.circle-four {
	fill: #d4f0f0;
	opacity: 0.6;
	right: 185px;
	top: 500px;
	position: absolute;
}

.circle-five {
	fill: #e1efba;
	opacity: 0.6;
	left: -80px;
	top: 50px;
	position: absolute;
}

.circle-six {
	fill: #d4f0f0;
	opacity: 1;
	left: -85px;
	top: 180px;
	position: absolute;
}

.container{
	margin-bottom: 1rem;
}
.clio-import{
	padding-right: 5px;
}
/* .custom-select{
	width: 40% !important;
} */
#deleteAgreement .modal-body{
	text-align: center;
}
.btn-color{
	background-color: #3F6772;
}
.new-client{
	/* width: 100%; */
	float: right;
}

.delete-multiple, .delete-multiple:disabled, .delete-multiple:focus{
	border-color: #d83c2d;
	color: #d83c2d;
	background-color: white;
}

.delete-multiple:hover, .btn-secondary:not(:disabled):not(.disabled):active{
	color: white;
	background-color: #d83c2d;
	border-color: white;
}
.clio-client{
	/* width: 100%; */
	float: left;
}
/* .duplicate, .duplicate:focus{
	background-color: rgb(108, 117, 125) !important;
} */
.btn-secondary:focus, .btn-secondary.focus, .btn-secondary:not(:disabled):not(.disabled):active:focus{
	-webkit-box-shadow: none;
	box-shadow: none;
}

.dateColumn, .descriptionColumn{
	background-position: left calc(14.75rem /2) center !important;
	width: 272px;
	/* white-space: nowrap; */
}
/*.table td.descriptionColumn, .table td.dateColumn{
	font-size: 12px !important;
}*/

.description-container {
    max-height: calc(7 * 1.4em); 
    overflow: hidden;
    position: relative; 
}

.description {
    white-space: pre-line; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

.nameColumn > div, .dateColumn > div{
	padding-bottom: 4px;
}

.searchbar > .input-group-prepend > .input-group-text{
	background-color: transparent;
	color: #b8bdc0;
	border: none;
}

.searchinput{
	border: none;
}

.searchinput:focus{
	box-shadow: none !important;
}

.searchbar{
	border: 1px solid;
	border-color: #b8bdc0;
	border-radius: 5px;
}

::-webkit-search-cancel-button{
	cursor: pointer;
}

@media (max-width: 992px) {
	.dateColumn {
    	background-position: left calc(9.75rem /2) center !important;
	}
}

@media (min-width: 768px) {
	.dateColumn {
		white-space: nowrap;
	}
}
@media(min-width: 320px) and (max-width: 395px) {
	.searchbar{
		width: 100%; 
	}
	.justify-content-end{
		-webkit-box-pack: justify !important;
		justify-content: space-between !important;
	}
	.nameColumn, .dateColumn, .deleteColumn, .duplicateColumn{
		padding-left: 10px !important;
	}
	.nameColumn > div{
		width: 5rem;
		background-position: left calc(9rem /2) center !important;
		text-align-last: center;
	}
	.dateColumn > div{
		width: 4.25rem;
	}
	.deleteColumn > .float-styling, .duplicateColumn > .float-styling{
		float: left;
	}
	.nameColumn,.deleteColumn,.checkbox, .duplicateColumn, .dateColumn{
		vertical-align: middle !important;
	}
	.dateColumn{
		text-align: center;
	}
	.col-5-mob{
		-webkit-box-flex: 0 !important;
		flex: 0 0 49% !important;
		max-width: 49% !important;
		padding-right: 0px !important;
	}
	.deleteColumn{
		max-width: 3.75rem;
	}
	.nameColumn{
		max-width: 6rem;
	}
	.dateColumn{
		max-width: 7rem;
	}
}
@media(min-width: 395px) and (max-width: 480px) {
	.searchbar{
		width: 100%; 
	}
	.justify-content-end{
		-webkit-box-pack: justify !important;
		justify-content: space-between !important;
	}
	.nameColumn, .dateColumn, .deleteColumn, .duplicateColumn{
		padding-left: 10px !important;
	}
	.nameColumn > div{
		width: 5rem;
		background-position: left calc(9rem /2) center !important;
		text-align-last: center;
	}
	.dateColumn > div{
		width: 4.25rem;
	}
	.deleteColumn > .float-styling, .duplicateColumn > .float-styling{
		float: left;
	}
	.nameColumn,.deleteColumn,.checkbox, .duplicateColumn, .dateColumn{
		vertical-align: middle !important;
	}
	.dateColumn{
		text-align: center;
	}
	.col-5-mob{
		-webkit-box-flex: 0 !important;
		flex: 0 0 54.66666667% !important;
		max-width: 55.66666667% !important;
		padding-right: 0px !important;
	}
	.deleteColumn{
		max-width: 4.063rem;
	}
	.nameColumn {
		max-width: 6rem;
	}
	.dateColumn{
		max-width: 7rem;
	}
}

@media(min-width: 481px) and (max-width: 1281px){
	.deleteColumn > .float-styling, .duplicateColumn > .float-styling{
		float: right;
	}
	.home-wrapper {
		min-width: 400px;
	}
}
</style>

<template>
	<b-container class="mt-5">
		<trial-banner />
		<b-card class="home-wrapper">
			<h4 slot="header">Client Files</h4>
			<b-row>
				<b-col class="text-right mb-3 d-flex justify-content-end">
					<div class="col-4 col-5-mob">
						<b-input-group class="searchbar" v-if="filteredAgreements.length>0 && !loadingAgreements">
							<b-input-group-prepend is-text>
								<b-icon-search></b-icon-search>
							</b-input-group-prepend>
							<b-form-input
								type="search"
								placeholder="Search by client names"
								class="searchinput"
								v-model="searchTerm"
								data-testid="search-agreements"
							/>
						</b-input-group>
					</div>
					<b-button class="btn-color" variant="primary" @click="addNewClient()" v-if="!loadingAgreements && isAddAllowed" data-testid="add-new-client-method"
						:class="{'btn-disabled-but-clickable': trialExpired}">
						+ Add new client
						<b-badge variant="light" v-if="agreementsCountLeft!=null && agreementsCountLeft!=undefined">{{agreementsCountLeft}} left</b-badge>
					</b-button>
				</b-col>
				
			</b-row>
			<!-- <b-row>{{trialActive}} == {{filteredAgreements.length}} ++ {{trialExpired}}</b-row> -->
			<b-table show-empty :items="searchResults" :fields="fields" :sort-compare="sortCompare" sort-icon-left>
				<template #head(checkbox) v-if="isDeleteAllowed">
					<b-form-checkbox v-if="searchResults.length>0" v-model="selectAll" data-testid="select-all-checkbox"/>
				</template>
				<template #head(delete)>
					<b-button size="sm" :style="{ visibility: checkedAgreements.length>0 ? 'visible' : 'hidden' }" @click="showDeleteModel(checkedAgreements,'multiple')" class="delete-multiple" :disabled="deleteLoader">
						<template v-if="deleteLoader">
						<b-spinner small type="grow"></b-spinner>
							Deleting...
						</template>
						<template v-else>
							Delete selected
						</template>
					</b-button>
				</template>
				<template #cell(checkBox)="{ item: agreement }"  v-if="isDeleteAllowed">
					<div v-if="agreement.owner">
					<b-form-checkbox  v-model="checkedAgreements" :value="agreement.id"
						:data-testid="`select-agreement-checkbox-${agreement.id}`"></b-form-checkbox>
					</div>
				</template>
				<template #cell(name)="{ item: agreement }">
					<b-link
						:to="{
							name: 'agreement',
							params: { id: agreement.id },
						}"
						:disabled="isViewMode"
						>{{ agreement.title || agreement.id }} 
						</b-link
					>
					<b-badge v-if="iscloned && agreement.id==clonedId" variant="secondary">Cloned</b-badge>
					&nbsp;<b-icon-people-fill variant="navy" v-if="agreement.isAgreementShared"></b-icon-people-fill>
				</template>
				<template #cell(description)="{ item: agreement }">
					<div class="description-container">
						<div class="description ellipsis">{{ agreement.description }}</div>
					</div>
				</template>
				<template #cell(delete)="{ item: agreement }" v-if="isDeleteAllowed">
					<b-button
						v-if="agreement.owner"
						variant="pastel-blue"
						size="sm"
						class="float-styling"
						:class="{'btn-disabled-but-clickable': trialExpired}"
						@click="showDeleteModel(agreement.id,'single')"
						data-testid="delete-model"
					>
						<b-icon-trash />
					</b-button>
				</template>
				<template #cell(duplicate)="{ item: agreement }">
					<b-button
					variant="pastel-blue"
					size="sm"
					class="float-styling duplicate"
					@click="isDuplicationAllowed(agreement)"
					data-testid="duplicate-model"
					:class="{'btn-disabled-but-clickable': trialExpired}"
					>
					<template v-if="cloningStatus[agreement.id]">
						<b-spinner small type="grow"></b-spinner></template>
					<template v-else>
						<b-icon-back />
					</template>
					</b-button>
				</template>
				<template #empty>
					<b-card no-body>
						<div
							v-if="loadingAgreements || clioLoading"
							class="
								text-center
								w-100
								d-flex
								justify-content-center
								align-items-center
							"
							style="height: 200px"
						>
							<b-spinner
								style="width: 3rem; height: 3rem"
								variant="primary"
								label="Loading..."
							/>
						</div>
						<b-card-body v-else class="text-center">
							<b-card-title>You have no agreements</b-card-title>
							<b-card-text>
								Divii looks simple, but has so much to offer.
							</b-card-text>
							<b-button
								variant="primary"
								@click="addNewClient()"
								v-if="isAddAllowed"
							>
								Create agreement
							</b-button>
						</b-card-body>
					</b-card>
				</template>
				<template>
					<div
						v-if="clioLoading"
						class="
							text-center
							w-100
							d-flex
							justify-content-center
							align-items-center
						"
						style="height: 200px"
					>
						<b-spinner
							style="width: 3rem; height: 3rem"
							variant="primary"
							label="Loading..."
						/>
					</div>
				</template>
			</b-table>
			<b-modal
				id="deleteAgreement"
				v-model="showDeleteConfirmation"
				:title="agreementDeleteModalOptions.heading"
				@ok="deleteMultiple? deleteMultipleAgreements(): deleteAgreement()"
				@hide="hideModal"
				data-testid="delete-agreement"
				cancel-variant="pastel-blue"
			>
				<p>
					{{agreementDeleteModalOptions.message}}
				</p>
			</b-modal>

			<b-modal
				id="duplicateAgreement"
				v-model="showDuplicateConfirmation"
				:title="agreementDuplicateModalOptions.heading"
				@ok="duplicateAgreement()"
				@hide="hideModal"
				data-testid="duplicate-agreement"
				cancel-variant="pastel-blue"
			>
				<p>
					{{agreementDuplicateModalOptions.message}}
				</p>
			</b-modal>

			<b-modal
				id="agreementLimitTrial"
				v-model="showAgreementLimit"
				:title="agreementLimitModalOptions.heading"
				hide-footer
				@hide="showAgreementLimit = false"
			>
				<p>
					{{agreementLimitModalOptions.message}}
				</p>
				<b-link :to="{ name: 'subscription' }"> {{agreementLimitModalOptions.button}} </b-link>
			</b-modal>

			<b-modal
				id="addClientMethods"
				v-model="showAddClient"
				title=" Import from Clio"
			>	
				Do you want to import agreement from Clio?
				<template #modal-footer="{}">
					<b-button size="sm" variant="primary" @click="clioShowModal()" data-testid="cancel-button"> Yes </b-button>
					<b-button size="sm" variant="navy" @click="routeToCreateAgreement()" data-testid="cancel-button"> No </b-button>
				</template>
					<!-- <b-form-group >
						<div class="clio-client"> -->
							<!-- <b-button class="bg-color mt-3" variant="navy" @click="clioShowModal()" data-testid="import-clio-file-btn">
									Yes
							</b-button>
							<b-badge href="#" variant="light" v-if="agreementsCountLeft!=null && agreementsCountLeft!=undefined">{{agreementsCountLeft}} left</b-badge> -->
							<template v-if="showClioModal"><clio-import
							title= "Import Clio File"
							:show-clio-modal="showClioModal"
							:hide-clio-modal="hideClioModal"
							:save-details="saveDetails"
							@isLoading="loader"
							class="modal-body"
							/></template>
						<!-- </div> -->
						<!-- <div class="new-client">
							<b-link class="btn btn-primary mt-3" @click="addNewClient()" v-if="!loadingAgreements" data-testid="add-new-client">
								No
								<b-badge href="#" variant="light" v-if="agreementsCountLeft!=null && agreementsCountLeft!=undefined">{{agreementsCountLeft}} left</b-badge>
							</b-link>
						</div> -->
					<!-- </b-form-group> -->
			</b-modal>
		</b-card>
		
		<!-- <div class="circle-one">
			<svg
				preserveAspectRatio="none"
				data-bbox="0 0 200 200"
				xmlns="http://www.w3.org/2000/svg"
				width="200"
				height="200"
				viewBox="0 0 200 200"
				role="presentation"
				aria-hidden="true"
			>
				<g>
					<path
						d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
					></path>
				</g>
			</svg>
		</div>
		<div class="circle-two">
			<svg
				preserveAspectRatio="none"
				data-bbox="0 0 200 200"
				xmlns="http://www.w3.org/2000/svg"
				width="162"
				height="162"
				viewBox="0 0 200 200"
				role="presentation"
				aria-hidden="true"
			>
				<g>
					<path
						d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
					></path>
				</g>
			</svg>
		</div>
		<div class="circle-three">
			<svg
				preserveAspectRatio="none"
				data-bbox="0 0 200 200"
				xmlns="http://www.w3.org/2000/svg"
				width="162"
				height="162"
				viewBox="0 0 200 200"
				role="presentation"
				aria-hidden="true"
			>
				<g>
					<path
						d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
					></path>
				</g>
			</svg>
		</div>
		<div class="circle-four">
			<svg
				preserveAspectRatio="none"
				data-bbox="0 0 200 200"
				xmlns="http://www.w3.org/2000/svg"
				width="275"
				height="275"
				viewBox="0 0 200 200"
				role="presentation"
				aria-hidden="true"
			>
				<g>
					<path
						d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
					></path>
				</g>
			</svg>
		</div> -->
		<subscription-modal :showSubscriptionModal="showSubscriptionModal" @close="showSubscriptionModal = false" :mode="'trialExpired'"/>
	</b-container>
</template>
